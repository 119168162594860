import React, { Fragment } from "react";

import { format, isBefore, addMinutes } from "date-fns";

import { formatShortDistance, displayPhoto } from "../utils";

import {
  ChatBubbleLeftIcon,
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";

import { useNavigate } from "react-router-dom";
import EventProps from "../interfaces/EventProps";
import { auth } from "../firebase";

const EventRow: React.FC<{ event: EventProps }> = ({ event }) => {
  const navigate = useNavigate();
  if (!event) return null;

  // Figure out if the current user has not RSVPd to the event
  const user = auth.currentUser;
  if (!user) return null;
  const userAttendee = event.attendees.find(
    (attendee) => attendee.email === user.email
  );
  const eventInvite =
    userAttendee && userAttendee.responseStatus === "needsAction";
  const eventPassed = isBefore(addMinutes(event.end.toDate(), 10), new Date());
  const eventCancelled = event.status === "cancelled";
  const eventDeclined = userAttendee?.responseStatus === "declined";

  // Format display of event duration (e.g. 1h, 30m, 2d, or "All Day")
  const duration = formatShortDistance(
    event.end.toDate(),
    event.start.toDate()
  );
  const isDaysOrMore = /^\d+d$/.test(duration) && parseInt(duration) >= 2;

  return (
    <li
      key={event.id}
      className={`flex flex-col items-top transition-shadow hover:bg-gray-50 border-t duration-100 cursor-pointer w-full ${
        eventPassed
          ? `bg-gray-50 border-2 border-gray-200 p-3 ${
              eventInvite ? "border-gray-250" : ""
            }`
          : eventInvite
            ? "bg-white border border-dashed border-purple-400 p-3 shadow-sm hover:shadow-md hover:bg-white"
            : "bg-white shadow-md hover:shadow-lg p-3"
      }`}
      onClick={() => navigate(`/e/${event.id}`)}
    >
      <div className="flex flex-row">
        <div className="truncate w-1/3 pl-8">
          {eventPassed && !eventCancelled && !eventInvite && (
            <CheckCircleIcon className="w-6 h-6 text-green-500 inline mr-1"></CheckCircleIcon>
          )}
          {eventInvite && eventPassed && (
            <MinusCircleIcon className="w-6 h-6 text-green-500 inline mr-1" />
          )}
          {eventCancelled && (
            <XCircleIcon className="w-6 h-6 text-red-500 mr-1 inline align-top"></XCircleIcon>
          )}
          <h2
            className={`inline text-black ${
              eventPassed ? "text-sm font-medium" : "text-md mb-1 font-medium"
            }`}
          >
            {event.title}
          </h2>
        </div>
        {!eventPassed ? (
          <>
            <div className="text-gray-500 w-1/6">
              {duration !== "All day" && !isDaysOrMore ? (
                <>{format(event.start.toDate(), "MMM dd h:mmaaa")} • </>
              ) : (
                <>{format(event.start.toDate(), "MMM dd")} • </>
              )}
              {duration}
            </div>
            {event.comments?.length > 0 && (
              <span className="ml-auto">
                <ChatBubbleLeftIcon className="w-4 h-4 mr-1" />
                <span className="text-gray-500">{event.comments.length}</span>
              </span>
            )}
            <span className="text-gray-500 text-sm flex">
              {event.attendeeData.map((attendee: any, index: number) => {
                if (attendee.email === user.email && eventInvite) return null;
                if (index === 5) {
                  return "+" + (event.attendeeData.length - 5);
                } else if (index < 5) {
                  return (
                    <Fragment key={attendee.email}>
                      {attendee.email === user.email && eventDeclined && (
                        <span className="relative">
                          <XMarkIcon className="w-5 h-5 text-red-500 inline -mr-1" />
                        </span>
                      )}
                      <img
                        src={displayPhoto(attendee.photo)}
                        alt={attendee.email}
                        className={`w-6 h-6 rounded-full border-2 border-white ${
                          index !== 0 ? "-ml-2" : ""
                        }`}
                      />
                    </Fragment>
                  );
                }
              })}
            </span>
          </>
        ) : (
          ""
        )}
      </div>
    </li>
  );
};

export default EventRow;
