import React, { useMemo, useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  addDays,
  addWeeks,
  startOfWeek,
  isSameDay,
  format,
  isMonday,
  isFriday,
  differenceInDays,
  nextFriday,
} from "date-fns";

import EventCard from "./EventCard";
import EventProps from "../interfaces/EventProps";
import EventListFilters from "./EventListFilters";

interface WeekProps {
  week: string;
}

export const getCurrentWeekStartDate = (filters: any): Date => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  if (isMonday(now)) return now;
  return startOfWeek(now, { weekStartsOn: 1 });
};

export const getNextWeekStartDate = (): Date => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return startOfWeek(addWeeks(now, 1), { weekStartsOn: 1 });
};

export const getCurrentWeekEndDate = (): Date => {
  const now = new Date();
  now.setHours(23, 59, 59, 999);
  if (isFriday(now)) return now;
  return nextFriday(now);
};

export const getNextWeekEndDate = (): Date => {
  const now = new Date();
  now.setHours(23, 59, 59, 999);

  if (isFriday(now)) return nextFriday(now);
  return nextFriday(getNextWeekStartDate());
};

const Week: React.FC<WeekProps> = (props) => {
  const [events, setEvents] = useState<EventProps[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<EventProps[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    declined: false,
    cancelled: false,
    invites: true,
    pastDays: false,
  });
  const [user, setUser] = useState<any>(null);
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  const startDate = useMemo(
    () =>
      props.week === "this-week"
        ? getCurrentWeekStartDate(filters)
        : getNextWeekStartDate(),
    [props.week, filters]
  );

  const endDate = useMemo(
    () =>
      props.week === "this-week"
        ? getCurrentWeekEndDate()
        : getNextWeekEndDate(),
    [props.week]
  );
  const daysToDisplay = differenceInDays(endDate, startDate) + 1;

  // Make sure we're logged in and then fetch the events
  useEffect(() => {
    let eventsUnsubscribe: any;

    const fetchEventsFromFirestore = async (userId: string) => {
      const q = query(
        collection(db, "events"),
        where("userIds", "array-contains", userId),
        where("start", ">=", startDate),
        where("start", "<=", endDate),
        orderBy("start")
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const eventsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().title,
          description: doc.data().description,
          location: doc.data().location,
          onlineLocation: doc.data().onlineLocation,
          start: doc.data().start,
          end: doc.data().end,
          organizer: doc.data().organizer,
          attendees: doc.data().attendees,
          attendeeData: doc.data().attendeeData,
          comments: doc.data().comments,
          status: doc.data().status || "",
        }));

        setEvents(eventsData);
      });

      return unsubscribe;
    };

    const authUnsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        eventsUnsubscribe = fetchEventsFromFirestore(user.uid);
      } else {
        navigate("/signin");
      }
    });

    return () => {
      authUnsubscribe();
      if (typeof eventsUnsubscribe === "function") {
        eventsUnsubscribe();
      }
    };
  }, [navigate, endDate, startDate]);

  useEffect(() => {
    const filteredEvents = events.filter((event) => {
      // Check if the event is cancelled
      if (event.status === "cancelled") {
        return filters.cancelled;
      }

      // Check if the event is declined
      if (event.attendees) {
        const attendee = event.attendees.find(
          (attendee) => attendee.email === user.email
        );
        if (attendee && attendee.responseStatus === "declined") {
          return filters.declined;
        }
      }

      // For each event, check if it's an invite
      if (event.attendees) {
        const attendee = event.attendees.find(
          (attendee) => attendee.email === user.email
        );
        if (attendee && attendee.responseStatus === "needsAction") {
          return filters.invites;
        }
      }

      return true;
    });

    setFilteredEvents(filteredEvents);
    setLoading(false);
  }, [filters, events, user]);

  const renderDayHeader = (date: Date) => {
    if (isSameDay(date, new Date())) return "Today";
    if (isSameDay(date, addDays(new Date(), 1))) return "Tomorrow";
    return format(date, "EEEE");
  };

  // Count the amount of events in this week and create a percentage
  // out of 25 meetings in a week
  const filteredEventsLength = filteredEvents.length;
  const eventPercentage = (filteredEventsLength / 25) * 100;

  return (
    <div>
      {loading ? (
        <div className="p-10">Loading...</div>
      ) : (
        <div>
          <div className="pl-4">
            <div className="float-right pr-4">
              {filteredEvents.length > 0 ? (
                <EventListFilters
                  filters={filters}
                  setFilters={setFilters}
                  showPastDaysOption={props.week === "this-week"}
                />
              ) : null}
            </div>
            <div>
              <h1 className="text-2xl font-bold pt-2">
                {props.week === "this-week" ? "This Week" : "Next Week"}
              </h1>
              <p className="text-gray-600 mt-1">
                <span
                  className={`text-gray-500 mt-1 ${
                    eventPercentage > 110
                      ? "text-red-600 font-semibold"
                      : eventPercentage > 92
                      ? "text-red-600"
                      : eventPercentage > 80
                      ? "text-orange-600"
                      : "text-green-600"
                  }`}
                >
                  {eventPercentage.toFixed(0)}%
                </span>{" "}
                full • {filteredEventsLength} events
              </p>
            </div>
          </div>
          <div>
            {filteredEvents.length === 0 ? (
              <p className="text-gray-500">
                Welcome to Paceful! We're syncing the events from your
                calendar...
              </p>
            ) : (
              <div className="flex flex-nowrap overflow-x-auto mt-4">
                {[...Array(daysToDisplay)].map((_, index) => {
                  let currentDate = addDays(startDate, index);

                  if (!filters.pastDays && currentDate < startOfDay)
                    return null;

                  const eventsForDay = filteredEvents.filter((event) => {
                    return isSameDay(event.start.toDate(), currentDate);
                  });

                  return (
                    <div key={index} className="min-w-[310px] p-4 pt-6">
                      <div className="mb-6">
                        <h2 className="text-l font-semibold mb-1 inline">
                          {renderDayHeader(currentDate)}
                        </h2>
                        <p className="text-gray-500 text-sm inline ml-3">
                          {format(currentDate, "MMM d")}
                        </p>
                      </div>
                      <ul className="space-y-7">
                        {eventsForDay.length > 0 ? (
                          eventsForDay.map((event) => (
                            <EventCard key={event.id} event={event}></EventCard>
                          ))
                        ) : (
                          <p className="text-gray-500">
                            {index === 0
                              ? "All done for today!"
                              : "Nothing here. Yay!"}
                          </p>
                        )}
                      </ul>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Week;
