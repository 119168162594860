import React from "react";

import TextareaAutosize from "react-textarea-autosize";
import Comment from "./Comment";

interface CommentsProps {
  handleAddComment: () => void;
  setNewComment: (comment: string) => void;
  event: { comments: { id: string; [key: string]: any }[] };
  newComment: string;
}

const EventDetailComments: React.FC<CommentsProps> = ({
  handleAddComment,
  setNewComment,
  event,
  newComment,
}) => {
  return (
    <>
      <div className="mt-8">
        <div className="relative">
          <TextareaAutosize
            className="w-full h-15 border border-gray-300 rounded-lg p-3 pr-28"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Write a comment or add meeting notes..."
            onKeyDown={(e) => {
              if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                handleAddComment();
              }
            }}
          />
          <button
            className="absolute right-3 bottom-4 bg-purple-600 text-white px-4 py-1 rounded text-sm"
            onClick={handleAddComment}
          >
            Send
          </button>
        </div>
      </div>
      {event.comments.map((comment: any) => (
        <Comment comment={comment} key={comment.id} />
      ))}
    </>
  );
};

export default EventDetailComments;
