import React, { useMemo, useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  addDays,
  addWeeks,
  startOfWeek,
  isFriday,
  differenceInDays,
  nextFriday,
} from "date-fns";

import EventRow from "./EventRow";
import EventProps from "../interfaces/EventProps";
import EventListFilters from "./EventListFilters";

interface LaterProps {}

export const getNextWeekStartDate = (): Date => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return startOfWeek(addWeeks(now, 1), { weekStartsOn: 1 });
};

export const getNextWeekEndDate = (): Date => {
  const now = new Date();
  now.setHours(23, 59, 59, 999);

  if (isFriday(now)) return nextFriday(now);
  return nextFriday(getNextWeekStartDate());
};

const Later: React.FC<LaterProps> = () => {
  const [events, setEvents] = useState<EventProps[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<EventProps[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    declined: false,
    cancelled: false,
    invites: true,
    pastDays: false,
  });
  const [user, setUser] = useState<any>(null);
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  const startDate = useMemo(() => addDays(getNextWeekEndDate(), 1), []);

  // Make sure we're logged in and then fetch the events
  useEffect(() => {
    let eventsUnsubscribe: any;

    const fetchEventsFromFirestore = async (userId: string) => {
      const q = query(
        collection(db, "events"),
        where("userIds", "array-contains", userId),
        where("start", ">", startDate),
        orderBy("start")
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const eventsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().title,
          description: doc.data().description,
          location: doc.data().location,
          onlineLocation: doc.data().onlineLocation,
          start: doc.data().start,
          end: doc.data().end,
          organizer: doc.data().organizer,
          attendees: doc.data().attendees,
          attendeeData: doc.data().attendeeData,
          comments: doc.data().comments,
          status: doc.data().status || "",
        }));

        setEvents(eventsData);
      });

      return unsubscribe;
    };

    const authUnsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        eventsUnsubscribe = fetchEventsFromFirestore(user.uid);
      } else {
        navigate("/signin");
      }
    });

    return () => {
      authUnsubscribe();
      if (typeof eventsUnsubscribe === "function") {
        eventsUnsubscribe();
      }
    };
  }, [navigate, startDate]);

  useEffect(() => {
    const filteredEvents = events.filter((event) => {
      // Check if the event is cancelled
      if (event.status === "cancelled") {
        return filters.cancelled;
      }

      // Check if the event is declined
      if (event.attendees) {
        const attendee = event.attendees.find(
          (attendee) => attendee.email === user.email
        );
        if (attendee && attendee.responseStatus === "declined") {
          return filters.declined;
        }
      }

      // For each event, check if it's an invite
      if (event.attendees) {
        const attendee = event.attendees.find(
          (attendee) => attendee.email === user.email
        );
        if (attendee && attendee.responseStatus === "needsAction") {
          return filters.invites;
        }
      }

      return true;
    });

    setFilteredEvents(filteredEvents);
    setLoading(false);
  }, [filters, events, user]);

  return (
    <div>
      {loading ? (
        <div className="p-10">Loading...</div>
      ) : (
        <div className="bg-gray-100">
          <div className="pt-12 pl-12">
            <h1 className="text-xl font-bold pb-2">Later</h1>
            {filteredEvents.length > 0 ? (
              <EventListFilters
                filters={filters}
                setFilters={setFilters}
                showPastDaysOption={false}
              />
            ) : null}
          </div>
          <div>
            {filteredEvents.length === 0 ? (
              <p className="text-gray-500">
                Welcome to Paceful! We're syncing the events from your
                calendar...
              </p>
            ) : (
              <div className="flex flex-nowrap overflow-x-auto">
                <ul className="w-full pt-8">
                  {filteredEvents.map((event) => (
                    <EventRow key={event.id} event={event}></EventRow>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Later;
